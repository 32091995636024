var initialState = {
  selected: null,
  steps: {
    fetched: false,
    error: null,
    data: {},
    sorted: []
  },
  styles: {
    fetched: false,
    error: null,
    data: {},
    bySelectorStepId: {}
  },
  fields: {
    fetched: false,
    error: null,
    data: {},
    shortcuts: []
  },
  search: {
    q: null,
    ids: [],
    trackId: null,
    types: [],
    steps: [],
    fields: {},
    progress: {
      in_time: true,
      overdue: true
    },
    offset: 0,
    limit: -1,
    order: 'TITLE_ASC'
  },
  services: {
    fetched: false,
    error: null,
    data: {},
    hashCode: null
  },
  servicesById: {
    data: {} // services details
  },
  carouselView: {
    fetched: false,
    error: null,
    data: {}
  },
  topbarView: {
    advancedSearch: {
      open: false,
      fields: {}
    }
  },
  mapView: {
    zoom: null,
    latitude: null,
    longitude: null,
    focusedIn: []
  },
  sidebarView: {
    open: true,
    screen: 'result_list',
    detailScreen: {
      tab: 'info',
      showAllCustomFields: false
    }
  }
};

var actions = {
  'MAP_PAGE.UPDATE_STEPS': function (state, action) {
    return { ...state, steps: action.value };
  },
  'MAP_PAGE.UPDATE_STYLES': function (state, action) {
    return { ...state, styles: action.value };
  },
  'MAP_PAGE.UPDATE_FIELDS': function (state, action) {
    return { ...state, fields: action.value };
  },
  'MAP_PAGE.SET_SEARCH': function (state, action) {
    return state;
  },
  'MAP_PAGE.FETCH_SERVICE_IMAGES': function (state, action) {
    const { id } = action.payload;
    const servicesById = { ...state.servicesById };

    if (!servicesById.data[id]) {
      servicesById.data[id] = {};
    }

    servicesById.data[id].images = {
      fetched: false,
      error: null,
      data: null
    };

    return { ...state, servicesById: servicesById };
  },
  'MAP_PAGE.FETCH_SERVICE_IMAGES_DONE': function (state, action) {
    console.log('Fetch services done', action)
    const { id, result } = action.payload;
    const servicesById = { ...state.servicesById };

    if (!servicesById.data[id]) {
      servicesById.data[id] = {};
    }

    servicesById.data[id].images = { ...result };

    return { ...state, servicesById: servicesById };
  }
};

export default function (state = initialState, action) {
  if (actions[action.type]) {
    return actions[action.type](state, action);
  }

  return state;
}
