import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
//import devToolsEnhancer from 'remote-redux-devtools';

import reducers from './reducers';
import sagaRoot from './sagas';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  reducers,
  applyMiddleware(sagaMiddleware),
  //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

sagaMiddleware.run(sagaRoot);
export default store;
