import { all, takeLatest, takeEvery } from 'redux-saga/effects';

import fetchProjects from './fetchProjects';
import fetchServices from './fetchServices';
import fetchServiceImages from './fetchServiceImages';
import sessionSaga from './session.js';
import eventSaga from './log-event.js';

import resetSearchSaga from './reset-search/index.js';
import genericEventSaga from './generic-event/index.js';

const clickEventSaga = (componentName) => {
  return genericEventSaga('click', (action) => {
    return {
      content: {
        ...action.payload,
        component: componentName
      }
    }
  });
}

export default function* sagaRoot () {
  yield all([
    //takeLatest('INITIALIZE', initialize),
    takeLatest('SEARCH.UPDATE', fetchServices),
    takeLatest('SEARCH.FORCE', fetchServices),
    takeLatest('SEARCH.FORCE_SUBMIT', fetchServices), // Added
    takeLatest('SEARCH.CLEAR', resetSearchSaga),
    takeLatest('USERS.LOAD_PROJECTS', fetchProjects),
    takeEvery('MAP_PAGE.FETCH_SERVICE_IMAGES', fetchServiceImages),
    takeEvery('SESSION', sessionSaga),
    takeEvery('EVENT', eventSaga),

    takeEvery('SERVICE.ATTRIBUTES_EXPAND', clickEventSaga('service_properties_expand')),
    takeEvery('SIDEBAR.DETAIL_TAB_CHANGE', clickEventSaga('service_tabs')),
    takeEvery('CAROUSEL.OPEN', clickEventSaga('carousel')),
    takeEvery('SIDEBAR.DETAIL_FILE_DOWNLOAD', genericEventSaga('download'))
  ]);
}
