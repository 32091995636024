import axios from 'axios';
import store from '../store';
import session from '../shared/session';

import getCurrentProject from '../store/selectors/getCurrentProject';

async function makeGraphQLClient () {
  var user = session.user;
  var project = getCurrentProject(store.getState());

  var graphqlClientOptions = {
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: {
      'Authorization': `Bearer ${session.getToken()}`,
      'X-Project-Id': project.id,
      'X-User-Id': user.id
    }
  };

  var graphqlClient = axios.create(graphqlClientOptions);
  return graphqlClient;
}

export default makeGraphQLClient;
