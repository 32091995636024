import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagReact from '@bugsnag/plugin-react';

const bugsnag = Bugsnag({
  appType: 'client',
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  releaseStage: process.env.NODE_ENV,
  enabledReleaseStages: ['production']
});

bugsnag.use(BugsnagReact, React);

export default bugsnag;
