import { put, delay } from 'redux-saga/effects';

function* asyncResetSearch () {
  let forceSubmitSagaParams = {
    type: 'SEARCH.FORCE_SUBMIT',
    payload: {}
  };

  let resetSearchEventParams = {
    type: 'EVENT',
    payload: {
      event: 'reset_search',
      metadata: {}
    }
  };

  yield delay(100);
  yield put(forceSubmitSagaParams);
  yield put(resetSearchEventParams);
}

export default asyncResetSearch;
