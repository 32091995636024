import { put } from 'redux-saga/effects';

const DEFAULT_MAP_ACTION_TO_EVENT = (action) => {
  return {
    content: {
      ...action.payload
    }
  }
};

const genericEventSaga = (eventType = 'click', mapActionToEvent = DEFAULT_MAP_ACTION_TO_EVENT) => {
  return function* (action) {
    console.log('Generic event SAGA:', { event: eventType, action });
    const mapFn = mapActionToEvent || DEFAULT_MAP_ACTION_TO_EVENT;
    const mapResponse = mapFn(action) || {};

    let logEventParams = {
      type: 'EVENT',
      payload: {
        event: eventType || 'click',
        ...mapResponse
      }
    };

    yield put(logEventParams);
  }
};

export default genericEventSaga;