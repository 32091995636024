const initialState = {
  touched: false,
  fetching: false,
  authenticated: false,
  error: null,
  user: null
};

const actions = {};

actions['AUTH.SET_AUTHENTICATION_STATE'] = (state, action) => {
  return {
    ...state,
    ...action.payload,
  }
};

export default function (state = initialState, action) {
  if (actions[action.type]) {
    return actions[action.type](state, action);
  }

  return state;
}
