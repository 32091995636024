/**
 * Get current signed user from state
 * @param {Object} state
 * @returns {null|Object} signed user
 */
function getCurrentUser (state) {
  var user = null;

  if (state.users.currentUserId) {
    const { currentUserId } = state.users;

    if (state.users.data[currentUserId]) {
      user = state.users.data[currentUserId];
    }
  }

  return user;
}

export default getCurrentUser;
