const initialState = {
  isSigned: false,
  isFetching: false,
  token: null,
  user: {
    id: null,
    name: null,
    email: null,
    isFetching: false,
    data: {
      name: null,
      email: null
    }
  },
  project: {
    id: null,
    name: null,
    isFetching: false
  }
};

export default function (state = initialState, action) {
  if (action.type === 'SESSION.SET_USER') {
    return {
      ...state,
      user: action.value
    }
  }

  if (action.type === 'SESSION.SET_PROJECT') {
    return {
      ...state,
      project: action.value
    }
  }

  if (action.type === 'SESSION.SET_TOKEN') {
    return {
      ...state,
      token: action.value
    }
  }

  return state;
}